import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PartnerActionCreators } from "../redux/actions/partner";
import { GeneralActionCreators } from "../redux/actions/general";
import LogoModal from "../components/modals/LogoModal";
import { AwardsCard } from "../components/profile/AwardsCard";
import { withToastManager } from "react-toast-notifications";
import { TestimonialsCard } from "../components/profile/TestimonialsCard";
import { SEO } from "../components/global/SEO";
import OnBoardingService from "../components/on-boarding/OnBoardingService";
import UpdateDetailsModal from "../components/modals/UpdateDetailsModal";
import { FullTestimonialDetails } from "../components/partners/FullTestimonialDetails";

class Profile extends Component {
  state = {
    partner: {},
    user: {},
    tab: 0,
    modal: null,
    toggle: null,
    loading: false,
    activeTesti: null,
  };

  regions = createRef();
  services = createRef();
  sectors = createRef();

  async componentWillMount() {
    const { genActions, baseData, toastManager } = this.props;
    await this.getPartnerData();
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        toastManager.add("Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
  }

  getPartnerData = async () => {
    const { actions } = this.props;
    await actions.getDetails();
    await actions.getUser();
    const { getDetailsErr, userErr } = this.props;
    if (getDetailsErr || userErr) {
      /*toastManager.add("Something went wrong.", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });*/
    }
  };

  toggleModal = (modal = null) => this.setState({ modal });

  handleLogoSuccess = async () => {
    this.toggleModal();
    await this.getPartnerData();
  };

  handleRequestTestimonialClick = () =>
    this.setState({ toggle: "requestTestimonial" }, () =>
      this.setState({ toggle: null })
    );

  handleSave = async () => {
    const { loading } = this.state;
    const { toastManager } = this.props;
    if (!loading) {
      try {
        this.setState({ loading: true });
        let actions = [];

        if (this.regions.current)
          actions.push(this.regions.current.handleSubmit());
        if (this.services.current)
          actions.push(this.services.current.handleSubmit());
        if (this.sectors.current)
          actions.push(this.sectors.current.handleSubmit());
        await Promise.all(actions);
        this.setState({ loading: false });
        toastManager.add("All changes saved.", {
          appearance: "success",
          autoDismiss: true,
          pauseOnHover: false,
        });
      } catch (e) {
        console.log(e);
        this.setState({ loading: false });
        toastManager.add("Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
  };

  render() {
    const { modal, tab, toggle, loading, activeTesti } = this.state;
    const { partnerDetails, user, baseData } = this.props;
    const partner = partnerDetails;

    if (JSON.stringify(partner) === "{}") return <div>Loading...</div>;

    const activeTestimonial =
      partner.testimonials &&
      partner.testimonials.find((x) => x.id === activeTesti);
    return (
      <div className="view profile">
        <SEO
          title="Profile on Aesthetix Connect | Partners"
          slug="profile"
          creator="aesthetixconnect"
        />
        <div className="profile-content">
          <section className="static-top">
            <div className="container">
              <div className="row">
                <div className="col-md-2">
                  <img
                    src={partner.logo}
                    alt="logo"
                    className="partner-logo"
                    onClick={() => this.toggleModal("logo")}
                  />
                </div>
                <div className="col">
                  <div className="partner-details">
                    <h1>{partner.name}</h1>
                    <div className="ratings">
                      <span>{Number(partner.rating || "0").toFixed(1)}</span>
                      <span>({partner.totalRatings} ratings)</span>
                    </div>
                    <div className="buttons">
                      <button
                        className="btn btn-primary"
                        onClick={this.handleRequestTestimonialClick}
                      >
                        Request testimonial
                      </button>
                      <a
                        href={partner.website}
                        target="_blank" rel="noopener noreferrer"
                        className="btn btn-outline-primary"
                      >
                        Visit website
                      </a>
                      {user && user.roleId === 1 && (
                        <button
                          className="btn btn-link"
                          onClick={() => this.toggleModal("details")}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="profile-tabs">
            <div className="tabs-row">
              <div
                className={"tab-title" + (tab === 0 ? " active" : "")}
                onClick={() => this.setState({ tab: 0 })}
              >
                Bio
              </div>
              <div
                className={"tab-title" + (tab === 1 ? " active" : "")}
                onClick={() => this.setState({ tab: 1 })}
              >
                Services
              </div>
              <div
                className={"tab-title" + (tab === 2 ? " active" : "")}
                onClick={() => this.setState({ tab: 2 })}
              >
                Testimonials
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container">
              {JSON.stringify(baseData) !== "{}" && (
                <div className={"profile-row step-" + tab}>
                  <div>
                    <div className="row">
                      <div className="col-md-6">
                        <h3>About</h3>
                        <p>{partner.bio}</p>
                      </div>
                      <div className="col-md-6 awards">
                        <AwardsCard awards={partner.awards} />
                      </div>
                    </div>
                  </div>
                  <div className="services">
                    <h3>Services</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <OnBoardingService
                          title="What services do you provide?"
                          theKey="services"
                          profile
                          ref={this.services}
                          onSuccess={this.nextStep}
                        />
                        <OnBoardingService
                          title="What sectors do you cater to?"
                          theKey="sectors"
                          profile
                          ref={this.sectors}
                          onSuccess={this.nextStep}
                        />
                      </div>
                      <div className="col-md-6">
                        <OnBoardingService
                          title="What regions do you provide services to?"
                          theKey="regions"
                          profile
                          ref={this.regions}
                          onSuccess={this.nextStep}
                        />
                      </div>
                      <div className="col text-center">
                        <button
                          className="btn btn-primary"
                          onClick={this.handleSave}
                        >
                          {loading ? "Saving" : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="testimonials">
                    <div className="row">
                      <div className="col-md-6">
                        <TestimonialsCard
                          testimonials={partner.testimonials}
                          toggle={toggle === "requestTestimonial"}
                          setActiveTesti={(e) =>
                            this.setState({ activeTesti: e })
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <FullTestimonialDetails {...activeTestimonial} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
        <LogoModal
          isOpen={modal === "logo"}
          close={this.toggleModal}
          success={this.handleLogoSuccess}
        />
        <UpdateDetailsModal
          isOpen={modal === "details"}
          close={this.toggleModal}
          success={this.handleLogoSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getDetailsErr: state.PartnerReducer.getDetailsErr,
  partnerDetails: state.PartnerReducer.partnerDetails,
  userErr: state.PartnerReducer.userErr,
  user: state.PartnerReducer.user,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
