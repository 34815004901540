import React from "react";
import {RatingContainer} from "../global/RatingContainer";

export const FullTestimonialDetails = props =>  {
    const {custName, productUsed, satisfaction, prodSatisfaction, trainingQuality, salesRepRel, prodKnowledge, partnershipFlex,afterSalesService, marketing,reliability, projectDesc} = props;
    if(!custName) return null;
    return <div className="full-testimonial-details">
        <div className="details">
            <div className="row">
                <div className="col-12 col-md-7">
                    <span className="title">Product</span>
                </div>
                <div className="col-12 col-md-5">
                    <span className="value">{productUsed}</span>
                </div>
                <div className="col-12 col-md-7">
                    <span className="title">Overall</span>
                </div>
                <div className="col-12 col-md-5">
                    <RatingContainer rating={Number(satisfaction)} hideRating/>
                </div>
                <div className="col-12 col-md-7">
                    <span className="title">Product Satisfaction</span>
                </div>
                <div className="col-12 col-md-5">
                    <RatingContainer rating={prodSatisfaction} hideRating/>
                </div>
                <div className="col-12 col-md-7">
                    <span className="title">Training Quality</span>
                </div>
                <div className="col-12 col-md-5">
                    <RatingContainer rating={trainingQuality} hideRating/>
                </div>
                <div className="col-12 col-md-7">
                    <span className="title">Sales Representative Relationship</span>
                </div>
                <div className="col-12 col-md-5">
                    <RatingContainer rating={salesRepRel} hideRating/>
                </div>
                <div className="col-12 col-md-7">
                    <span className="title">Product Knowledge</span>
                </div>
                <div className="col-12 col-md-5">
                    <RatingContainer rating={prodKnowledge} hideRating/>
                </div>
                <div className="col-12 col-md-7">
                    <span className="title">Partnership Flexibility</span>
                </div>
                <div className="col-12 col-md-5">
                    <RatingContainer rating={partnershipFlex} hideRating/>
                </div>
                <div className="col-12 col-md-7">
                    <span className="title">After Sales Service</span>
                </div>
                <div className="col-12 col-md-5">
                    <RatingContainer rating={afterSalesService} hideRating/>
                </div>
                <div className="col-12 col-md-7">
                    <span className="title">Marketing</span>
                </div>
                <div className="col-12 col-md-5">
                    <RatingContainer rating={marketing} hideRating/>
                </div>
                <div className="col-12 col-md-7">
                    <span className="title">Reliability</span>
                </div>
                <div className="col-12 col-md-5">
                    <RatingContainer rating={reliability} hideRating/>
                </div>
            </div>
            <p>{projectDesc}</p>
        </div>
    </div>
};
