import React, { useState } from "react";
import { TestimonialRequestedModal } from "../../components/modals/TestimonialRequestedModal";
import RequestTestimonialForm from "../../forms/RequestTestimonialForm";
import { SEO } from "../../components/global/SEO";
import { AuthRightPanel } from "../../components/on-boarding/AuthRightPanel";
import { OnBoardingIntro } from "../../components/on-boarding/OnBoardingIntro";
import { Back } from "../../components/global/Back";

export const Testimonials = (props) => {
  const { history } = props;
  const [modalOpen, setModal] = useState(false);
  const [step, setStep] = useState(0);
  const [restart, setRestart] = useState(false);

  const handleRestart = () => {
    setRestart(true);
    setTimeout(() => setRestart(false), 1000);
    setModal(false);
  };

  return (
    <div className="view auth onboarding details">
      <SEO
        title="Request Testimonials for Aesthetix Connect | Partners"
        slug="on-boarding/testimonials"
        creator="aesthetixconnect"
      />
      <div className="auth-row">
        <div className="auth-left-panel">
          <div className="auth-panel-inner">
            <div className="details-container">
              <div className={"details-row step-" + step}>
                <OnBoardingIntro
                  title="Request Testimonials"
                  intro="Make your account stand out by requesting previous customers to leave a testimonial for your account..."
                  nextAction={() => setStep(1)}
                  nextText="Request Testimonials"
                  skipAction={() => history.push("/profile")}
                />
                <div>
                  <div className="auth-title-bar">
                    <Back onClick={() => setStep(0)} />
                    <h1>Request a Testimonial</h1>
                  </div>
                  <RequestTestimonialForm
                    onSuccess={() => setModal(true)}
                    restart={restart}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AuthRightPanel />
      </div>
      <TestimonialRequestedModal
        isOpen={modalOpen}
        close={() => setModal(false)}
        restart={() => handleRestart()}
      />
    </div>
  );
};
