import React, {Component} from "react";
import Modal from "react-modal";
import {bindActionCreators} from "redux";
import {PartnerActionCreators} from "../../redux/actions/partner";
import {withToastManager} from "react-toast-notifications";
import {connect} from "react-redux";
import AvatarEditor from "react-avatar-editor";

const initialState = {image: null, fileType: null, ext: null, rotate: 0, loading: false, error: null};

class LogoModal extends Component {
    state = initialState;

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isOpen !== this.props.isOpen) {
            this.setState(initialState);
            setTimeout(() => {
                if(this.fileInput && this.props.isOpen) this.fileInput.click()
            }, 10)
        }
    }

    onFileChange = e => {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        let splitted = files[0].name.split('.')
        let ext = splitted[splitted.length - 1]
        this.createImage(files[0], files[0].type, ext)
    }

    createImage = (file, fileType, ext) => {
        // var image = new Image()
        let reader = new FileReader()
        reader.onload = (e) => {
            this.setState({image:e.target.result, fileType, ext, error: null})
        }
        reader.readAsDataURL(file)
    }

    handleUploadImage = () => {
        const {actions, success} = this.props
        const {fileType, ext} = this.state
        if(this.editor) {
            const canvas = this.editor.getImage()
            this.setState({loading: true})
            canvas.toBlob(async theBlob => {
                try {
                    await actions.uploadLogo(theBlob, fileType, ext)
                    const {uploadLogoErr} = this.props
                    if(uploadLogoErr) {
                        this.setState({error: uploadLogoErr, loading: false})
                    } else {
                        this.setState(initialState)
                        success(canvas.toDataURL())
                        //close()
                    }
                } catch(e) {
                    console.log("Caught exception", e)
                    this.setState({error: "Something went wrong. Refresh and try again.", loading: false})
                }
            })
        }
    }

    rotate = type => {
        switch(type) {
            case "right":
                this.setState({rotate: this.state.rotate + 90})
                break;
            case "left":
                this.setState({rotate: this.state.rotate - 90})
                break;
            default:
                break;
        }
    }

    render() {
        const {isOpen, close} = this.props
        const {rotate, image, loading, error} = this.state
        return <Modal
            isOpen={isOpen}
            onRequestClose={close}
            className={"modal logo" + (image ? " image" : "")}
            overlayClassName="modal-overlay"
            contentLabel="Logo Modal">
            <input type='file' id='single' ref={c => this.fileInput = c} onChange={this.onFileChange} accept="image/png image/jpg image/jpeg" className="inputFile"/>
            {image ? <div className="pp-editor">
                <AvatarEditor
                    ref={e => {this.editor = e}}
                    width={400}
                    height={400}
                    rotate={rotate}
                    image={image} />
                <div className="rotate">
                   {/* <img src={rLeft} onClick={() => this.rotate("left")} />
                    <img src={rRight} onClick={() => this.rotate("right")} />*/}
                </div>
                <div className="bottom">
                    {error && <span className="form-error">{error}</span>}
                    <div className="buttons">
                        <label htmlFor="single" className="btn btn-link">Choose an image</label>
                        <button className="btn btn-primary" onClick={this.handleUploadImage} disabled={loading}>{loading ? "Uploading..." : "Upload"}</button>
                    </div>
                </div>
            </div> : <div className="choose-file">

                <label htmlFor="single" className="btn btn-link">Choose an image</label>
            </div>}
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    uploadLogoErr: state.PartnerReducer.uploadLogoErr
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(PartnerActionCreators, dispatch)
})

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(LogoModal));
