import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import RequestTestimonialForm from "../../forms/RequestTestimonialForm";
import {TestimonialRequestContent} from "./TestimonialRequestedContent";

export const RequestTestimonialModal = props => {
        const {isOpen, close} = props;
        const [success, setSuccess] = useState(false);

        useEffect(() => {
                if(isOpen) setSuccess(false);
        }, [isOpen]);

        return <Modal
            isOpen={isOpen}
            onRequestClose={close}
            className={"modal animated " + (success ? "testimonial-requested" : "request-testimonial")}
            overlayClassName="modal-overlay"
            contentLabel="Request Testimonial Modal">
                {success ? <TestimonialRequestContent>
                        <button className="btn btn-primary btn-block" onClick={close}>Done</button>
                </TestimonialRequestContent> : <div>
                        <h1>Request a Testimonial</h1>
                        <RequestTestimonialForm onSuccess={() => setSuccess(true)} close={close}/>
                </div>}
        </Modal>
}
