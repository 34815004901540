import React from "react";
import {RatingContainer} from "../global/RatingContainer";

export const PartnersList = props => {
    const {partners, onSelect, activeId} = props;
    return <div className="partners-list">
        <div className="intro">
            <h3>Top Results</h3>
        </div>
        <div className="list">
            {partners.map(partner => <div className={"partner-item" + (activeId === partner.id ? " active" :"")} key={partner.id} onClick={() => onSelect(partner.id)}>
                    <span className="partner-name">{partner.name}</span>
                    <RatingContainer rating={Number(partner.rating)} hideRating/>
                    <span className="partner-website">{partner.website}</span>
                </div>)}
        </div>
    </div>
}
