import React, {useState} from "react";
import {TestimonialDetails} from "../global/TestimonialDetails";
import {RequestTestimonialModal} from "../modals/RequestTestimonialModal";

export const TestimonialsCard = props => {
    const {testimonials = [], toggle = false, setActiveTesti} = props;
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    if(toggle && !modal) toggleModal();

    return <div className="awards-card">
        <div className="title-bar">
            <h3>Testimonials</h3>
            <button className="btn btn-primary" onClick={toggleModal}>Request</button>
        </div>
        {testimonials.map(testimonial => <TestimonialDetails key={testimonial.id} {...testimonial} onClick={() => setActiveTesti(testimonial.id)}/>)}
        <RequestTestimonialModal isOpen={modal} close={toggleModal}/>
    </div>
}
