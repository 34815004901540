import React, {Component} from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {
    formatDate,
} from 'react-day-picker/moment';
function YearMonthForm({ date, localeUtils, onChange, maxDate, toggleYears, open }) {
    const months = localeUtils.getMonths();
    const years = [];
    const year = maxDate ? maxDate.getFullYear() : new Date().getFullYear();
    for (let i = year; i >= 1920; i -= 1) {
        years.push(i);
    }
    const handleChange = function handleChange(year) {
        onChange(new Date(year, date.getMonth()));
    };
    return (
        <form className="DayPicker-Caption">
            <div className="active-month">
                {months[date.getMonth()]}
            </div>
            <div onClick={toggleYears} className="active-year">
                {date.getFullYear()}
                {open && <div className="day-picker-years">
                    {years.map(year => (
                        <div key={year} value={year} onClick={() => handleChange(year)}>
                            {year}
                        </div>
                    ))}
                </div>}
            </div>
        </form>
    );
}
export default class DatePicker extends Component {
    state = {
        open: false,
        year: this.props.date
    }
    handleYearChange = (year) => {
        this.setState({year, open: false});
    }
    render() {
        const {year, open} = this.state;
        const {disabled, maxDate = null, minDate = null, date, placeholder = "", change, error = false, classNames = {overlayWrapper: "DayPickerInput-OverlayWrapper", container: "", overlay: "DayPickerInput-Overlay"}} = this.props;
        let disabledDays = disabled ? {
            daysOfWeek: [0,1,2,3,4,5,6,7],
        } : {
            before: minDate,
            after: maxDate,
        };
        return <div>
            <DayPickerInput
                classNames={classNames}
                format="DD/MM/YYYY"
                formatDate={formatDate}
                placeholder={formatDate(maxDate, "DD/MM/YYYY")}
                value={date}
                dayPickerProps={{
                    disabledDays,
                    selectedDays: date,
                    month: year,
                    captionElement: ({ date, localeUtils }) => (
                        <YearMonthForm
                            date={date}
                            maxDate={maxDate}
                            localeUtils={localeUtils}
                            open={open}
                            toggleYears={() => {this.setState({open: !open})}}
                            onChange={this.handleYearChange}/>
                    )
                }}
                inputProps={{
                    readOnly: true,
                    className: "form-control day-picker" + (error ? " error" : ""),
                    placeholder
                }}
                onDayChange={change}
            />
        </div>
    }
}
