import { RFPActionTypes } from "../../types";

export const initialState = {
  loading: false,
  searchErr: null,
  contactErr: null,
  requests: [],
  requestErr: null,
  requestById: null,
};

export const RFPReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case RFPActionTypes.GET_RFPS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case RFPActionTypes.GET_RFPS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          searchErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        searchErr: null,
        requests: payload.requests,
      };
    }
    case RFPActionTypes.CONTACT_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case RFPActionTypes.CONTACT_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          contactErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        contactErr: null,
      };
    }
    case RFPActionTypes.GET_BY_ID_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case RFPActionTypes.GET_BY_ID_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          requestErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        requestById: payload.request,
      };
    }
    case "LOGOUT": {
      return initialState;
    }
    default:
      return state;
  }
};
