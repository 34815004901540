import React, { useState } from "react";
import { SEO } from "../../components/global/SEO";
import { AuthRightPanel } from "../../components/on-boarding/AuthRightPanel";
import OnboardingServicesSelection from "../../components/on-boarding/OnBoardingServicesSelection";

export const Services = (props) => {
  const [step, setStep] = useState(0);
  const handleSuccess = () => props.history.push("/on-boarding/team");

  return (
    <div className="view auth onboarding services">
      <SEO
        title="Add Services for Aesthetix Connect | Partners"
        slug="on-boarding/services"
        creator="aesthetixconnect"
      />
      <div className="auth-row">
        <div className="auth-left-panel">
          <div className="auth-panel-inner">
            <OnboardingServicesSelection
              activeStep={step}
              setStep={setStep}
              skip={handleSuccess}
              handleSuccess={handleSuccess}
            />
          </div>
        </div>
        <AuthRightPanel />
      </div>
    </div>
  );
};
