import React from "react";
import Modal from "react-modal";
import {Link} from "react-router-dom";
import {TestimonialRequestContent} from "./TestimonialRequestedContent";

export const TestimonialRequestedModal = props => {
        const {isOpen, close, restart} = props
        return <Modal
            isOpen={isOpen}
            onRequestClose={close}
            className="modal testimonial-requested"
            overlayClassName="modal-overlay"
            contentLabel="Testimonial Requested Modal">
                <TestimonialRequestContent>
                        <Link className="btn btn-primary btn-block" to="/">Go to dashboard</Link>
                        <button className="btn btn-outline-primary btn-block" onClick={restart}>Add another testimonial</button>
                </TestimonialRequestContent>
        </Modal>
}
