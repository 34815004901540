import React, {useState} from "react";
import {AwardDetails} from "./AwardDetails";
import AddAwardModal from "../modals/AddAwardModal";

export const AwardsCard = props => {
    const {awards = []} = props;
    /*const [edit, setEdit] = useState(false);*/
    const [add, setAdd] = useState(false);

    return <div className="awards-card">
        <div className="title-bar">
            <h3>Awards</h3>
            <button className="btn btn-primary" onClick={() => setAdd(true)}>Add</button>
        </div>
        {awards.map(award => <AwardDetails key={award.id} {...award}/>)}
        <AddAwardModal isOpen={add} close={() => setAdd(false)}/>
    </div>
}
