import { PartnerActionTypes } from "../../types";

export const initialState = {
  loading: false,
  updateDetailsErr: null,
  getDetailsErr: null,
  verifyEmailErr: null,
  partnerDetails: {},
  updateFiltersErr: null,
  testimonialErr: null,
  requestByToken: null,
  userErr: null,
  user: {},
  awardErr: null,
  distributors: [],
  searchErr: null,
  distributorsInfo: {},
  moreErr: null,
  inviteUserErr: null,
  allUsers: [],
};

export const PartnerReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case PartnerActionTypes.UPDATE_DETAILS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.UPDATE_DETAILS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          updateDetailsErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        updateDetailsErr: null,
      };
    }
    case PartnerActionTypes.GET_DETAILS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.GET_DETAILS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          getDetailsErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        getDetailsErr: null,
        partnerDetails: payload.distributor,
      };
    }
    case PartnerActionTypes.VERIFY_EMAIL_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.VERIFY_EMAIL_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          verifyEmailErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        verifyEmailErr: null,
      };
    }
    case PartnerActionTypes.UPDATE_USER_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.UPDATE_USER_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          userErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        userErr: null,
      };
    }
    case PartnerActionTypes.GET_USER_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.GET_USER_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          userErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        userErr: null,
        user: payload.user,
      };
    }
    case PartnerActionTypes.UPDATE_FILTERS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.UPDATE_FILTERS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          updateFiltersErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        updateFiltersErr: null,
      };
    }
    case PartnerActionTypes.REQ_TESTIMONIAL_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.REQ_TESTIMONIAL_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          testimonialErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        testimonialErr: null,
      };
    }
    case PartnerActionTypes.GET_TESTI_REQ_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.GET_TESTI_REQ_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          testimonialErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        testimonialErr: null,
        requestByToken: payload.request,
      };
    }
    case PartnerActionTypes.ADD_TESTI_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.ADD_TESTI_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          testimonialErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        testimonialErr: null,
      };
    }
    case PartnerActionTypes.ADD_AWARD_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.ADD_AWARD_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          awardErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        awardErr: null,
      };
    }
    case PartnerActionTypes.GET_PARTNERS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.GET_PARTNERS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          searchErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        searchErr: null,
        distributors: payload.distributors,
      };
    }
    case PartnerActionTypes.MORE_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.MORE_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          moreErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        moreErr: null,
        distributorsInfo: {
          ...state.distributorsInfo,
          [payload.distributorId]: payload.data.info,
        },
      };
    }
    case PartnerActionTypes.INVITE_USERS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.INVITE_USERS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          inviteUserErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        inviteUserErr: null,
      };
    }
    case PartnerActionTypes.GET_USERS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.GET_USERS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          userErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        userErr: null,
        allUsers: payload.team,
      };
    }
    case "LOGOUT": {
      return initialState;
    }
    default:
      return state;
  }
};
