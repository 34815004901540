import React from "react";
import {RatingContainer} from "./RatingContainer";

export const TestimonialDetails = props => {
    const {accumRating,productUsed,comments, onClick} = props;
    return <div className="testimonial-details" onClick={onClick}>
        <div className="spread">
            <RatingContainer rating={Number(accumRating)} hideRating/>
        </div>
        <div className="spread">
            <h5>{productUsed}</h5>
        </div>
        <p>{comments.length > 30 ? comments.substring(0 , 30) + '...' : comments}</p>
    </div>
}
