import { RFPActionTypes } from "../../types";
import { BytehogsAPI } from "../BytehogsAPI";

const SERVICE = "ac-db";

export const RFPActionCreators = {
  search: (data) => async (dispatch) => {
    dispatch({
      type: RFPActionTypes.GET_RFPS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "search" };
      const response = await BytehogsAPI(SERVICE, "requests", payload);
      dispatch({
        type: RFPActionTypes.GET_RFPS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: RFPActionTypes.GET_RFPS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  contactCustomer: (data) => async (dispatch) => {
    dispatch({
      type: RFPActionTypes.CONTACT_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "contact" };
      await BytehogsAPI(SERVICE, "requests", payload);
      dispatch({
        type: RFPActionTypes.CONTACT_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: RFPActionTypes.CONTACT_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getRFPById: (requestId) => async (dispatch) => {
    dispatch({
      type: RFPActionTypes.GET_BY_ID_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { requestId, sessionToken, operation: "getById" };
      const response = await BytehogsAPI(SERVICE, "requests", payload);
      dispatch({
        type: RFPActionTypes.GET_BY_ID_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: RFPActionTypes.GET_BY_ID_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
};
