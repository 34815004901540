import React, { useState } from "react";
import { SEO } from "../global/SEO";
import { TestimonialDetails } from "../global/TestimonialDetails";
import { AwardDetails } from "../partners/AwardDetails";
import { TestimonialDetailsModal } from "../modals/TestimonialDetailsModal";
import {RatingContainer} from "../global/RatingContainer";

const mapFilterValues = (baseData, filterType, filters) => {
  if (!baseData || JSON.stringify(baseData) === "{}") return null;
  const theValues = baseData[filterType];
  return filters
      .map((x) => {
        let value = theValues.find((y) => y.id === x && y);
        return value ? value.name : null
      })
      .join(", ");
};

export const PartnersDetails = (props) => {
  const { partner, partnerInfo, baseData } = props;
  const [activeTestimonial, setTestimonial] = useState({});
  if (!partner || !baseData) return null;
  return (
    <div className="partners-details">
      <SEO
        title={partner.name + " on Aesthetix Connect | Customers"}
        slug=""
        creator="aesthetixconnect"
      />
      <div className="top-bar">
        <div>
          <img
            className="partner-logo"
            src={partner.logo}
            alt={partner.name + " logo"}
          />
        </div>
        <div className="top-details">
          <div className="name-bar">
            <h4>{partner.name}</h4>
            <RatingContainer rating={Number(partner.rating)} hideRating/>
          </div>
          <div className="buttons">
            <a
              href={partner.website}
              target="_blank" rel="noopener noreferrer"
              className="btn btn-outline-primary"
            >
              Visit Website
            </a>
          </div>
        </div>
      </div>
      <h4>Summary</h4>
      <div className="partner-services-card">
        <div className="row">
          <div className="col-md-6">
            <div className="row  no-gutters">
              <div className="col-md-5">
                <span className="detail-title">Services:</span>
              </div>
              <div className="col-md-7">
                <span className="detail-value">
                  {mapFilterValues(
                    baseData,
                    "services",
                    partner.services
                      ? partner.services.split(",").map(Number) || []
                      : []
                  )}
                </span>
              </div>
              <div className="col-md-5">
                <span className="detail-title">Sectors:</span>
              </div>
              <div className="col-md-7">
                <span className="detail-value">
                  {mapFilterValues(
                    baseData,
                    "sectors",
                    partner.sectors
                      ? partner.sectors.split(",").map(Number) || []
                      : []
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row no-gutters">
              <div className="col-md-5">
                <span className="detail-title">Regions:</span>
              </div>
              <div className="col-md-7">
                <span className="detail-value">
                  {mapFilterValues(
                    baseData,
                    "regions",
                    partner.regions
                      ? partner.regions.split(",").map(Number) || []
                      : []
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="details">
        <h4>Company Description</h4>
        <p className="description">{partner.bio}</p>
      </div>
      {partnerInfo && partnerInfo.awards.length > 0 && (
        <div className="awards">
          <h4>Awards</h4>
          {partnerInfo.awards.map((award) => (
            <AwardDetails key={award.id} {...award} />
          ))}
        </div>
      )}
      {partnerInfo &&
        partnerInfo.testimonials.length > 0 &&
        partnerInfo.testimonials[0].id && (
          <div className="testimonials">
            <h4>Testimonials</h4>
            {partnerInfo.testimonials.map(
              (testimonial) =>
                testimonial.id && (
                  <TestimonialDetails
                    key={testimonial.id}
                    {...testimonial}
                    onClick={() => setTestimonial(testimonial)}
                  />
                )
            )}
          </div>
        )}
      <TestimonialDetailsModal
        testimonial={activeTestimonial}
        isOpen={JSON.stringify(activeTestimonial) !== "{}"}
        close={() => setTestimonial({})}
      />
    </div>
  );
};
