import React from "react";

export const YearsSelector = (props) => {
  const { value, onChange } = props;
  return (
    <select className="form-control" value={value} onChange={onChange}>
      <option value="0-1">0-1</option>
      <option value="1-3">1-3</option>
      <option value="3-5">3-5</option>
      <option value="5-10">5-10</option>
      <option value="10+">10+</option>
    </select>
  );
};

/*
0-1
1-3
3-5
5-10
10+
*/
