import React, { useState } from "react";
import { SEO } from "../../components/global/SEO";
import { AuthRightPanel } from "../../components/on-boarding/AuthRightPanel";
import { OnBoardingIntro } from "../../components/on-boarding/OnBoardingIntro";
import { Back } from "../../components/global/Back";
import InviteTeamForm from "../../components/Team/InviteTeamForm";
import { InvitedUsersModal } from "../../components/modals/InvitedUsersModal";

export const InviteTeam = (props) => {
  const { history } = props;
  const [modalOpen, setModal] = useState(false);
  const [step, setStep] = useState(0);

  return (
    <div className="view auth onboarding details team">
      <SEO
        title="Request Testimonials for Aesthetix Connect | Partners"
        slug="on-boarding/team"
        creator="aesthetixconnect"
      />
      <div className="auth-row">
        <div className="auth-left-panel">
          <div className="auth-panel-inner">
            <div className="details-container">
              <div className={"details-row step-" + step}>
                <OnBoardingIntro
                  title="Invite your team"
                  intro="Power up your account by inviting your team to join your company account. Team mates will be able to receive and respond to customer requests but only admin will be able to make edits to your company account."
                  nextAction={() => setStep(1)}
                  nextText="Invite my team"
                  skipAction={() => history.push("/on-boarding/testimonials")}
                />
                <div>
                  <div className="auth-title-bar">
                    <Back onClick={() => setStep(0)} />
                    <h1>Invite your team</h1>
                  </div>
                  <InviteTeamForm onSuccess={() => setModal(true)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AuthRightPanel />
      </div>
      <InvitedUsersModal
        isOpen={modalOpen}
        close={() => setModal(false)}
        onboarding
      />
    </div>
  );
};
