import React, { Component } from "react";
import Modal from "react-modal";
import { bindActionCreators } from "redux";
import { PartnerActionCreators } from "../../redux/actions/partner";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import DatePicker from "../global/DatePicker";
const initialState = {
  name: "",
  description: "",
  received: null,
  errors: {},
  loading: false,
};

class AddAwardModal extends Component {
  state = initialState;

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.isOpen && !this.props.isOpen) this.setState(initialState);
  }

  setValue = (key, value) => this.setState({ [key]: value });

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, description, received, loading } = this.state;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (!name) errors.name = "Required";
      if (!description) errors.description = "Required";
      if (!received) errors.received = "Required";
      if (Object.keys(errors).length > 0)
        this.setState({ errors, loading: false });
      else {
        const { actions } = this.props;
        try {
          await actions.addAward({ name, description, received });
          const { awardErr, close, toastManager } = this.props;
          if (awardErr)
            this.setState({ errors: { join: awardErr }, loading: false });
          else {
            await actions.getDetails();
            toastManager.add("Successfully added award", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: false,
            });
            close();
          }
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  render() {
    const { isOpen, close } = this.props;
    const { received, name, description, errors, loading } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        className="modal add-award"
        overlayClassName="modal-overlay"
        contentLabel="Add Award Modal"
      >
        <h1>Add an Award</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="form-container">
            <div className="form-content">
              <div className="row">
                <div className="col-md-6">
                  <label>Award name</label>
                  <div className="wl-input-group">
                    {errors.name && (
                      <div className="input-err-message">{errors.name}</div>
                    )}
                    <input
                      type="text"
                      className={"form-control" + (errors.name ? " error" : "")}
                      value={name}
                      autoFocus
                      onChange={(e) => this.setValue("name", e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Date received</label>
                  <div className="wl-input-group">
                    {errors.received && (
                      <div className="input-err-message">{errors.received}</div>
                    )}
                    <DatePicker
                      date={received}
                      maxDate={new Date()}
                      change={(v) => this.setValue("received", v)}
                      error={errors.received}
                    />
                  </div>
                </div>
              </div>
              <label>Description</label>
              <div className="wl-input-group">
                {errors.description && (
                  <div className="input-err-message">{errors.description}</div>
                )}
                <textarea
                  className={
                    "form-control" + (errors.description ? " error" : "")
                  }
                  value={description}
                  onChange={(e) => this.setValue("description", e.target.value)}
                  rows={4}
                />
              </div>
              {errors.join && <div className="form-error">{errors.join}</div>}
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loading ? "Adding..." : "Add Award"}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  awardErr: state.PartnerReducer.awardErr,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(AddAwardModal)
);
