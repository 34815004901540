import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PartnerActionCreators } from "../../redux/actions/partner";
import { GeneralActionCreators } from "../../redux/actions/general";
import LogoModal from "../../components/modals/LogoModal";
import { OnBoardingProgress } from "../../components/on-boarding/OnBoardingProgress";
import { SEO } from "../../components/global/SEO";
import { AuthRightPanel } from "../../components/on-boarding/AuthRightPanel";
import { Back } from "../../components/global/Back";
import { YearsSelector } from "../../components/partners/YearsSelector";
class AddDetails extends Component {
  state = {
    website: "",
    bio: "",
    years: "0-1",
    step: 0,
    errors: {},
    loading: false,
    modalOpen: false,
    image: null,
  };

  async componentWillMount() {
    const { genActions } = this.props;
    try {
      await genActions.getBaseData();
    } catch (e) {
      console.log("e", e);
    }
  }

  setValue = (key, value) => {
    this.setState({ [key]: value });
    this.handleValidation(true);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { website, bio, step, years, loading } = this.state;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      this.setState({ loading: true });
      if (step === 0) {
        const validationErrors = this.handleValidation();
        if (validationErrors > 0) this.setState({ loading: false });
        else this.setState({ step: 1, loading: false });
      } else {
        const validationErrors = this.handleValidation();
        if (validationErrors > 0) this.setState({ loading: false });
        else {
          const { actions } = this.props;
          try {
            await actions.updateDetails({ bio, website, years });
            const { updateDetailsErr, history } = this.props;
            if (updateDetailsErr)
              this.setState({
                errors: { join: updateDetailsErr },
                loading: false,
              });
            else history.push("/on-boarding/services");
          } catch (e) {
            this.setState({
              errors: { join: e.message || "Sorry, something went wrong" },
              loading: false,
            });
          }
        }
      }
    }
  };

  handleValidation = (onlyExistingErrors = false) => {
    const { website, bio, step, image } = this.state;
    if (
      (onlyExistingErrors && Object.keys(this.state.errors).length > 0) ||
      !onlyExistingErrors
    ) {
      let errors = {};
      this.setState({ errors });
      if (step === 0) {
        if (!website || website.length < 6) errors.website = "Required";
        else if (
          !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/i.test(
            website
          )
        )
          errors.website = "Invalid Website";
        if (!image) errors.image = "Required";
        if (Object.keys(errors).length > 0) this.setState({ errors });
      } else {
        if (!bio || bio.length < 6) errors.bio = "Required";
        if (Object.keys(errors).length > 0) this.setState({ errors });
      }
      return Object.keys(errors).length;
    }
  };

  handleLogoSuccess = (image) => this.setState({ modalOpen: false, image });

  handleYearsChange = (e) => this.setValue("years", e.target.value);

  render() {
    const {
      website,
      bio,
      years,
      step,
      errors,
      loading,
      modalOpen,
      image,
    } = this.state;
    return (
      <div className="view auth onboarding details">
        <SEO
          title="Add Details for Aesthetix Connect | Partners"
          slug="on-boarding/details"
          creator="aesthetixconnect"
        />
        <div className="auth-row">
          <div className="auth-left-panel">
            <div className="auth-panel-inner">
              <div className={"auth-title-bar step-" + step}>
                <Back onClick={() => this.setValue("step", step - 1)} />
                <h1>Company Details</h1>
              </div>
              <OnBoardingProgress index={step} steps={2} />
              <div className="details-container">
                <div className={"details-row step-" + step}>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-container">
                      <div className="form-content">
                        <label>Add your company logo</label>
                        <div
                          className={
                            "upload-logo" + (errors.image ? " error" : "")
                          }
                          onClick={() => this.setValue("modalOpen", true)}
                        >
                          {image && <img src={image} alt="logo" />}
                        </div>
                        <label>Company website</label>
                        <div className="wl-input-group">
                          {errors.website && (
                            <div className="input-err-message">
                              {errors.website}
                            </div>
                          )}
                          <input
                            type="text"
                            className={
                              "form-control" + (errors.website ? " error" : "")
                            }
                            placeholder="Company Website"
                            value={website}
                            onChange={(e) =>
                              this.setValue("website", e.target.value)
                            }
                          />
                        </div>
                        <label>
                          How many years have you been a distributor?
                        </label>
                        <div className="wl-input-group">
                          {errors.years && (
                            <div className="input-err-message">
                              {errors.years}
                            </div>
                          )}
                          <YearsSelector
                            value={years}
                            onChange={this.handleYearsChange}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={loading}
                        >
                          {loading ? "Saving..." : "Next"}
                        </button>
                      </div>
                    </div>
                  </form>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-container">
                      <div className="form-content">
                        <label>Describe your company</label>
                        <div className="wl-input-group">
                          {errors.bio && (
                            <div className="input-err-message">
                              {errors.bio}
                            </div>
                          )}
                          <textarea
                            className={
                              "form-control" + (errors.bio ? " error" : "")
                            }
                            placeholder="Please provide a short description of your company and/or what services your company provides."
                            value={bio}
                            onChange={(e) =>
                              this.setValue("bio", e.target.value)
                            }
                            rows={7}
                          />
                        </div>
                        {errors.join && (
                          <div className="form-error">{errors.join}</div>
                        )}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={loading}
                        >
                          {loading ? "Saving..." : "Next"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <AuthRightPanel />
        </div>
        <LogoModal
          isOpen={modalOpen}
          close={() => this.setValue("modalOpen", false)}
          success={this.handleLogoSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  updateDetailsErr: state.PartnerReducer.updateDetailsErr,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDetails);
