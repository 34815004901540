import React from "react";
export const TestimonialRequestContent = props => {
    const {children} = props;
    return <div className="testimonial-modal-content">
        {/*<img src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-email-sent.svg" className="email-sent"/>*/}
        <h1>Request Sent</h1>
        <p>We’ve sent your an email to your testimonial asking them to add a reference</p>
        {children}
    </div>
}
