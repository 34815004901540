import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { AuthActionCreators } from "../../redux/actions/auth";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SEO } from "../../components/global/SEO";
import { AuthRightPanel } from "../../components/on-boarding/AuthRightPanel";

class Login extends Component {
  state = { email: "", password: "", errors: {}, loading: false };

  setValue = (key, value) => {
    this.setState({ [key]: value });
    this.handleValidation(true);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, loading } = this.state;
    if (!loading) {
      this.setState({ loading: true });
      const validationErrors = this.handleValidation();
      if (validationErrors > 0) this.setState({ loading: false });
      else {
        const { actions } = this.props;
        try {
          await actions.login({ email, password });
          const { loginErr, history } = this.props;
          if (loginErr)
            this.setState({ errors: { join: loginErr }, loading: false });
          else history.push("/profile");
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  handleValidation = (onlyExistingErrors = false) => {
    const { email, password } = this.state;
    if (
      (onlyExistingErrors && Object.keys(this.state.errors).length > 0) ||
      !onlyExistingErrors
    ) {
      let errors = {};
      this.setState({ errors });
      if (!email) errors.email = "Required";
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email))
        errors.email = "Invalid email";
      if (!password || password.length < 6) errors.password = "Required";
      if (Object.keys(errors).length > 0) this.setState({ errors });
      return Object.keys(errors).length;
    }
  };

  render() {
    const { email, password, errors, loading } = this.state;
    return (
      <div className="view auth login">
        <SEO
          title="Login to Aesthetix Connect  | Partners"
          slug="login"
          creator="aesthetixconnect"
        />
        <div className="auth-row">
          <div className="auth-left-panel">
            <div className="auth-panel-inner">
              <div className="auth-title-bar">
                <h1>Sign In</h1>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-container">
                  <div className="form-content">
                    <label>Email</label>
                    <div className="wl-input-group">
                      {errors.email && (
                        <div className="input-err-message">{errors.email}</div>
                      )}
                      <input
                        type="email"
                        className={
                          "form-control" + (errors.email ? " error" : "")
                        }
                        placeholder="Email"
                        value={email}
                        onChange={(e) => this.setValue("email", e.target.value)}
                      />
                    </div>
                    <label>Password</label>
                    <div className="wl-input-group">
                      {errors.password && (
                        <div className="input-err-message">
                          {errors.password}
                        </div>
                      )}
                      <input
                        type="password"
                        className={
                          "form-control" + (errors.password ? " error" : "")
                        }
                        placeholder="Password"
                        value={password}
                        onChange={(e) =>
                          this.setValue("password", e.target.value)
                        }
                      />
                    </div>
                    {errors.join && (
                      <div className="form-error">{errors.join}</div>
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={loading}
                    >
                      {loading ? "Signing In..." : "Sign In"}
                    </button>
                  </div>
                </div>
              </form>
              <div className="redirect-actions">
                <p>
                  Trouble logging in?{" "}
                  <Link
                    to="/forgot-password"
                    className="btn btn-link btn-inline"
                  >
                    Reset password
                  </Link>
                </p>
                <p className="mt-3">
                  Don't have an account yet?
                  <Link to="/register" className="btn btn-link btn-inline">
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <AuthRightPanel />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginErr: state.AuthReducer.loginErr,
  isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
