import { PartnerActionTypes } from "../../types";
import { BytehogsAPI } from "../BytehogsAPI";
import axios from "axios";

const SERVICE = "ac-db";

export const PartnerActionCreators = {
  updateDetails: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.UPDATE_DETAILS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken };
      const response = await BytehogsAPI(SERVICE, "updateDetails", payload);
      dispatch({
        type: PartnerActionTypes.UPDATE_DETAILS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.UPDATE_DETAILS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getDetails: () => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.GET_DETAILS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { sessionToken };
      const response = await BytehogsAPI(SERVICE, "getDetails", payload);
      dispatch({
        type: PartnerActionTypes.GET_DETAILS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.GET_DETAILS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  verifyEmail: (code) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.VERIFY_EMAIL_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { sessionToken, operation: "verify", code };
      const response = await BytehogsAPI(SERVICE, "verifyEmail", payload);
      dispatch({
        type: PartnerActionTypes.VERIFY_EMAIL_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.VERIFY_EMAIL_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  resendEmailCode: () => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.VERIFY_EMAIL_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { sessionToken, operation: "resend" };
      const response = await BytehogsAPI(SERVICE, "verifyEmail", payload);
      dispatch({
        type: PartnerActionTypes.VERIFY_EMAIL_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.VERIFY_EMAIL_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getUser: () => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.GET_USER_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { sessionToken, operation: "getCurrent" };
      const response = await BytehogsAPI(SERVICE, "user", payload);
      dispatch({
        type: PartnerActionTypes.GET_USER_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.GET_USER_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  updateUser: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.UPDATE_USER_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "update" };
      await BytehogsAPI(SERVICE, "user", payload);
      dispatch({
        type: PartnerActionTypes.UPDATE_USER_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.UPDATE_USER_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },

  uploadLogo: (image, fileType, fileExt) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.UPLOAD_LOGO_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = {
        sessionToken,
        fileType,
        fileExt,
      };
      const response = await BytehogsAPI(SERVICE, "uploadLogo", payload);
      await axios.put(response.data.uploadURL, image);
      dispatch({
        type: PartnerActionTypes.UPLOAD_LOGO_RES,
        payload: null,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.UPLOAD_LOGO_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  updateFilters: (filterType, filters) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.UPDATE_FILTERS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { filterType, filters, sessionToken };
      await BytehogsAPI(SERVICE, "updateFilters", payload);
      dispatch({
        type: PartnerActionTypes.UPDATE_FILTERS_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.UPDATE_FILTERS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  requestTestimonial: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.REQ_TESTIMONIAL_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, operation: "request", sessionToken };
      await BytehogsAPI(SERVICE, "testimonials", payload);
      dispatch({
        type: PartnerActionTypes.REQ_TESTIMONIAL_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.REQ_TESTIMONIAL_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getTestimonialByToken: (token) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.GET_TESTI_REQ_REQ,
    });
    try {
      let payload = { operation: "getDataByToken", token };
      const response = await BytehogsAPI(SERVICE, "testimonials", payload);
      dispatch({
        type: PartnerActionTypes.GET_TESTI_REQ_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.GET_TESTI_REQ_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  addTestimonial: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.ADD_TESTI_REQ,
    });
    try {
      let payload = { ...data, operation: "add" };
      await BytehogsAPI(SERVICE, "testimonials", payload);
      dispatch({
        type: PartnerActionTypes.ADD_TESTI_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.ADD_TESTI_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  addAward: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.ADD_AWARD_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, operation: "create", sessionToken };
      await BytehogsAPI(SERVICE, "awards", payload);
      dispatch({
        type: PartnerActionTypes.ADD_AWARD_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.ADD_AWARD_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  search: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.GET_PARTNERS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "search" };
      const response = await BytehogsAPI(
        SERVICE,
        "searchDistributors",
        payload
      );
      dispatch({
        type: PartnerActionTypes.GET_PARTNERS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.GET_PARTNERS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  searchByName: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.GET_PARTNERS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "searchByName" };
      const response = await BytehogsAPI(
        SERVICE,
        "searchDistributors",
        payload
      );
      dispatch({
        type: PartnerActionTypes.GET_PARTNERS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.GET_PARTNERS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getInfo: (distributorId) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.MORE_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { distributorId, sessionToken, operation: "getMore" };
      const response = await BytehogsAPI(
        SERVICE,
        "searchDistributors",
        payload
      );
      dispatch({
        type: PartnerActionTypes.MORE_RES,
        payload: { distributorId, data: response.data },
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.MORE_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getAllUsers: () => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.GET_USERS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { operation: "getAll", sessionToken };
      const response = await BytehogsAPI(SERVICE, "team", payload);
      dispatch({
        type: PartnerActionTypes.GET_USERS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.GET_USERS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  inviteUsers: (users) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.INVITE_USERS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { users, operation: "invite", sessionToken };
      await BytehogsAPI(SERVICE, "team", payload);
      dispatch({
        type: PartnerActionTypes.INVITE_USERS_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.INVITE_USERS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
};
