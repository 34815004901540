import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { PartnerActionCreators } from "../../redux/actions/partner";
import { GeneralActionCreators } from "../../redux/actions/general";
import { connect } from "react-redux";
import { OnBoardingIntro } from "./OnBoardingIntro";
import OnBoardingService from "./OnBoardingService";
import { Back } from "../global/Back";
import { OnBoardingProgress } from "./OnBoardingProgress";

class OnBoardingServicesSelection extends Component {
  async componentWillMount() {
    const { genActions, baseData, partnerDetails } = this.props;
    if (JSON.stringify(partnerDetails) === "{}") {
      this.refreshData();
    }
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        console.log("e", e);
      }
    }
  }

  nextStep = () => {
    const { activeStep, setStep } = this.props;
    this.refreshData();
    setStep(activeStep + 1);
  };

  refreshData = async () => {
    const { actions } = this.props;
    try {
      await actions.getDetails();
    } catch (e) {
      console.log("e", e);
    }
  };

  handleSuccess = async () => {
    const { handleSuccess } = this.props;
    await this.refreshData();
    handleSuccess();
  };

  render() {
    const { activeStep, setStep, skip, handleSuccess } = this.props;
    return (
      <div className="onboarding-services-selection">
        <div className={"top" + (activeStep > 0 ? " active" : "")}>
          <Back onClick={() => setStep(activeStep - 1)} />
          <h1>Your services</h1>
          <OnBoardingProgress index={activeStep - 1} steps={3} />
        </div>
        <div className="services-container">
          <div className={"services-row step-" + activeStep}>
            <div>
              <OnBoardingIntro
                title="Your services"
                intro="Configure the services that your business offers in order to receive automatic notifications when new requests are posted by businesses and to ensure your profile appears in business searches."
                nextText="Set my services"
                nextAction={this.nextStep}
                skipAction={skip}
              />
            </div>
            <div>
              <OnBoardingService
                title="What sector do you specialise in?"
                theKey="sectors"
                step={1}
                onSuccess={this.nextStep}
              />
            </div>
            <div>
              <OnBoardingService
                title="what category do you specialise in?"
                theKey="services"
                step={2}
                onSuccess={this.nextStep}
              />
            </div>
            <div>
              <OnBoardingService
                title="What region is your business located in?"
                theKey="regions"
                step={3}
                onSuccess={handleSuccess}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  partnerDetails: state.PartnerReducer.partnerDetails,
  updateDetailsErr: state.PartnerReducer.updateDetailsErr,
  updateFiltersErr: state.PartnerReducer.updateFiltersErr,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnBoardingServicesSelection);
