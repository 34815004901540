import React from "react";

export const RFPList = (props) => {
  const { requests, onSelect, activeId } = props;
  return (
    <div className="partners-list">
      <div className="intro">
        <h3>Matching results</h3>
      </div>
      <div className="list">
        {requests.map((request) => (
          <div
            className={
              "partner-item" + (activeId === request.id ? " active" : "")
            }
            key={request.id}
            onClick={() => onSelect(request.id)}
          >
            <span className="partner-name">{request.title}</span>
            <span className="partner-website">
              Posted by {request.customerName || "anonymous"}
            </span>
            <div className="bottom-details">
              {/*<div>
                <img
                  src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-clock.svg"
                  alt="clock"
                />
                {new Date(request.createdAt).toLocaleDateString()}
              </div>*/}
              <div>
                <img
                  src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-money.svg"
                  alt="money"
                />
                {request.expectedBudget}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
