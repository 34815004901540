import React, {useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {Logo} from "./Logo";
import ProfileDropdown from "./ProfileDropdown";
import SuperAdminModal from "../modals/SuperAdminModal";

const authPages = ['/register', '/login', '/forgot-password', '/on-boarding/verify', '/on-boarding/details', '/on-boarding/services', '/on-boarding/team', '/on-boarding/testimonials'];

const Header = props => {
    const [superAdminOpen, setAdminOpen] = useState(false);
    const isAuth = (authPages.includes(props.location.pathname) || /set-password/.test(props.location.pathname)  || /set-testimonial/.test(props.location.pathname));
    return <header className={"main-header" + (isAuth ? " auth" : "")}>
        <div className="left">
            <div className="brand">
                <Link to="/" className="desktop"><Logo/></Link>
                <Link to="/" className="mobile"><Logo mobile/></Link>
            </div>
            {!isAuth && <Link to="/" className="btn btn-link">Dashboard</Link>}
        </div>
        {!isAuth && <ProfileDropdown setAdminOpen={setAdminOpen}/>}
        <SuperAdminModal isOpen={superAdminOpen} close={() => setAdminOpen(false)}/>
    </header>
}

export default withRouter(Header);
