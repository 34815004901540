import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { AuthActionCreators } from "../../redux/actions/auth";
import { connect } from "react-redux";
import { PasswordResetModal } from "../../components/modals/PasswordResetModal";
import { Link } from "react-router-dom";
import { SEO } from "../../components/global/SEO";
import { AuthRightPanel } from "../../components/on-boarding/AuthRightPanel";

class ForgotPassword extends Component {
  state = { email: "", errors: {}, loading: false, success: false };

  setValue = (key, value) => this.setState({ [key]: value });

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email, loading } = this.state;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (!email) errors.email = "Required";
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email))
        errors.email = "Invalid email";
      if (Object.keys(errors).length > 0)
        this.setState({ errors, loading: false });
      else {
        const { actions } = this.props;
        try {
          await actions.forgotPassword({ email });
          const { pwErr } = this.props;
          if (pwErr) this.setState({ errors: { join: pwErr }, loading: false });
          else this.setState({ success: true, loading: false });
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  render() {
    const { email, errors, loading, success } = this.state;
    return (
      <div className="view auth register">
        <SEO
          title="Forgotten Password | Partners"
          slug="forgot-password"
          creator="aesthetixconnect"
        />
        <div className="auth-row">
          <div className="auth-left-panel">
            <div className="auth-panel-inner">
              <div className="auth-title-bar">
                <h1>Reset Password</h1>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-container">
                  <div className="form-content">
                    <label>Enter your email</label>
                    <div className="wl-input-group">
                      {errors.email && (
                        <div className="input-err-message">{errors.email}</div>
                      )}
                      <input
                        type="email"
                        className={
                          "form-control" + (errors.email ? " error" : "")
                        }
                        placeholder="Email"
                        value={email}
                        onChange={(e) => this.setValue("email", e.target.value)}
                      />
                    </div>
                    {errors.join && (
                      <div className="form-error">{errors.join}</div>
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={loading}
                    >
                      {loading ? "Sending..." : "Send Reset Link"}
                    </button>
                  </div>
                </div>
              </form>
              <div className="redirect-actions">
                <p>
                  Remembered your password?
                  <Link to="/login" className="btn btn-link btn-inline">
                    Sign In
                  </Link>
                </p>
              </div>
              <PasswordResetModal isOpen={success} />
            </div>
          </div>
          <AuthRightPanel />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pwErr: state.AuthReducer.pwErr,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
