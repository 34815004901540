import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import ReactGA from "react-ga";
import { AuthActionCreators } from "../../redux/actions/auth";
import { connect } from "react-redux";
import Login from "../../pages/auth/Login";
import Logout from "../../pages/auth/Logout";
import ForgotPassword from "../../pages/auth/ForgotPassword";
import Register from "../../pages/auth/Register";
import SetPassword from "../../pages/auth/SetPassword";
import AddDetails from "../../pages/on-boarding/AddDetails";
import { Services } from "../../pages/on-boarding/Services";
import Profile from "../../pages/Profile";
import { Testimonials } from "../../pages/on-boarding/Testimonials";
import Header from "./Header";
import SetTestimonial from "../../pages/SetTestimonial";
import { Toast } from "./Toast";
import ViewRFPs from "../../pages/ViewRFPs";
import RFPDetailPage from "../../pages/RFPDetailPage";
import VerifyEmail from "../../pages/on-boarding/VerifyEmail";
import ViewPartners from "../../pages/ViewPartners";
import Team from "../../pages/Team";
import { InviteTeam } from "../../pages/on-boarding/InviteTeam";
import Settings from "../../pages/Settings";
import AnalyticsTracker from "./AnalyticsTracker";

const ProtectedRoute = ({ isVerified, li, ...props }) => {
  return isVerified ? (
    <Route {...props} />
  ) : (
    <Redirect to={li ? "/profile" : "/login?return_url=" + props.path} />
  );
};

class Routing extends Component {
  constructor(props) {
    super(props);

    ReactGA.initialize('UA-178510820-2', {
      debug: false,
      titleCase: false
    });

    this.state = { canRender: false };
  }

  componentWillMount() {
    const { actions } = this.props;
    actions.checkToken().finally(() => this.setState({ canRender: true }));
  }

  render() {
    const { canRender } = this.state;
    const { isAuthed } = this.props;
    if (canRender) {
      return (
        <BrowserRouter>
          <ToastProvider components={{ Toast: Toast }}>
            <Header />
            <Switch>
              <ProtectedRoute
                isVerified={!isAuthed}
                li
                exact
                path="/register"
                component={AnalyticsTracker(Register)}/>
              <ProtectedRoute
                isVerified={!isAuthed}
                li
                exact
                path="/login"
                component={AnalyticsTracker(Login)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/logout"
                component={AnalyticsTracker(Logout)}/>
              <ProtectedRoute
                isVerified={!isAuthed}
                li
                exact
                path="/forgot-password"
                component={AnalyticsTracker(ForgotPassword)}/>
              <Route
                exact
                path="/set-password/:token"
                component={AnalyticsTracker(SetPassword)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                path="/"
                exact
                component={AnalyticsTracker(ViewRFPs)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                path="/distributors"
                exact
                component={AnalyticsTracker(ViewPartners)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                path="/rfp/:rfpId"
                exact
                component={AnalyticsTracker(RFPDetailPage)}/>
              <Redirect exact from="/on-boarding" to="/on-boarding/verify" />
              <ProtectedRoute
                isVerified={isAuthed}
                path="/on-boarding/verify"
                component={AnalyticsTracker(VerifyEmail)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                path="/on-boarding/details"
                component={AnalyticsTracker(AddDetails)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                path="/on-boarding/services"
                component={AnalyticsTracker(Services)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                path="/on-boarding/team"
                component={AnalyticsTracker(InviteTeam)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                path="/on-boarding/testimonials"
                component={AnalyticsTracker(Testimonials)}/>
              <Route
                exact
                path="/set-testimonial/:token"
                component={AnalyticsTracker(SetTestimonial)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                path="/team"
                component={AnalyticsTracker(Team)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                path="/profile"
                component={AnalyticsTracker(Profile)}/>
              <ProtectedRoute
                isVerified={isAuthed}
                path="/settings"
                component={AnalyticsTracker(Settings)}/>
              <Redirect to="/login" />
            </Switch>
          </ToastProvider>
        </BrowserRouter>
      );
    }
    return <div className="container">Loading...</div>;
  }
}

const mapStateToProps = (state) => ({
  isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
