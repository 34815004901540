import React, {Component} from 'react';
import Routing from "./components/global/Routing";
import "./assets/sass/index.scss"
import {Provider} from "react-redux";
import Modal from "react-modal";
import store from "./redux";

Modal.setAppElement('#root');

class App extends Component {
  componentWillReceiveProps(nextProps) {
    window.previousLocation = nextProps.location
  }

  render() {
    return (<Provider store={store}>
          <Routing/>
        </Provider>
    );
  }
}

export default App;
