import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { SEO } from "../components/global/SEO";
import InviteTeamForm from "../components/Team/InviteTeamForm";
import UsersList from "../components/Team/UsersList";
import { PartnerActionCreators } from "../redux/actions/partner";
import { InvitedUsersModal } from "../components/modals/InvitedUsersModal";

class Team extends Component {
  state = { modalOpen: false, loading: true };
  componentWillMount() {
    const { actions } = this.props;
    actions.getUser().finally(() => this.setState({ loading: false }));
  }

  render() {
    const { modalOpen, loading } = this.state;
    const { user } = this.props;
    return (
      <div className="view team">
        <SEO
          title="Manage Team | Partners"
          slug="team"
          creator="aesthetixconnect"
        />
        <div className="main-content">
          <div className="container">
            {user && user.roleId === 1 ? (
              <div className="row">
                <div className="col-md-6">
                  <h3>Manage your team</h3>
                  <UsersList />
                </div>
                <div className="col-md-6">
                  <h3>Invite your team</h3>
                  <InviteTeamForm
                    onSuccess={() => this.setState({ modalOpen: true })}
                  />
                </div>
              </div>
            ) : (
              <div className="text-center mt-5">
                <h2>
                  {loading
                    ? "Loading..."
                    : "Sorry, you don't have permission to access this page"}
                </h2>
              </div>
            )}
          </div>
        </div>
        <InvitedUsersModal
          isOpen={modalOpen}
          close={() => this.setState({ modalOpen: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.PartnerReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(Team)
);
