import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GeneralActionCreators } from "../redux/actions/general";
import { withToastManager } from "react-toast-notifications";
import { SEO } from "../components/global/SEO";
import { FiltersCard } from "../components/RFPs/FiltersCard";
import { PartnerActionCreators } from "../redux/actions/partner";
import { RFPActionCreators } from "../redux/actions/rfp";
import { PLMenu } from "../components/global/PLMenu";
import { PartnersDetails } from "../components/partners/PartnersDetails";
import { PartnersList } from "../components/partners/PartnersList";
import { Back } from "../components/global/Back";

class ViewRFPs extends Component {
  state = {
    sectors: [],
    business_areas: [],
    services: [],
    regions: [],
    products: [],
    distributors: [],
    activeId: null,
  };

  async componentWillMount() {
    const { genActions, baseData, toastManager } = this.props;
    await this.getPartnerData();
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        toastManager.add("Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
  }

  setValue = (key, value) => this.setState({ [key]: value });

  getPartnerData = async () => {
    const { parActions } = this.props;
    await parActions.getDetails();
    const { partnerDetails, getDetailsErr, toastManager } = this.props;
    if (getDetailsErr) {
      toastManager.add("Something went wrong.", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });
    } else {
      this.setState({ ...partnerDetails.filters });
      this.handleSearch();
    }
  };

  handlePillClick = (key, id, multi = true) => {
    let data = this.state[key];
    if (data.includes(id)) data.splice(data.indexOf(id), 1);
    else multi ? data.push(id) : (data = [id]);
    this.setState({ [key]: data });
    this.handleSearch();
  };

  handleRegionSelect = (regions) => {
    this.setState({ regions });
    this.handleSearch();
  };

  handleSearch = async (query = null) => {
    const { parActions } = this.props;
    const { sectors, regions, services, activeId } = this.state;
    if (!query)
      await parActions.search({
        services,
        sectors,
        regions,
      });
    else await parActions.searchByName({ query });
    const { searchErr, distributors, toastManager } = this.props;
    if (searchErr) {
      toastManager.add("Something went wrong.", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });
    } else {
      let newActiveId = null;
      if (activeId && distributors.find((x) => x.id === activeId && x))
        newActiveId = activeId;
      else if (distributors.length > 0) newActiveId = distributors[0].id;
      this.setState({ distributors });
      if (window.screen.availWidth > 768) {
        this.handlePartnerSelect(newActiveId);
      }
    }
  };

  handlePartnerSelect = async (partnerId) => {
    const { parActions } = this.props;
    try {
      await parActions.getInfo(partnerId);
      document.querySelector('body').scrollTo(0,0);
      this.setState({ activeId: partnerId });
    } catch (e) {
      console.log("e", e);
      this.setState({ activeId: partnerId });
    }
  };

  render() {
    const {
      business_areas,
      services,
      products,
      distributors,
      activeId,
      sectors,
      regions,
    } = this.state;
    const { customerDetails, baseData, distributorsInfo } = this.props;
    const activePartner = distributors.find((x) => x.id === activeId && x);
    return (
      <div className="view view-partners">
        <SEO
          title="View Distributors on Aesthetix Connect | Customers"
          slug=""
          creator="aesthetixconnect"
        />
        <div className="main-content">
          <PLMenu />

          <div className={"container" + (activePartner ? " active" : "")}>
            <section className="filters">
              <FiltersCard
                title="View all Distributors matching the following"
                RFP={false}
                baseData={baseData}
                customer={customerDetails}
                handlePillClick={this.handlePillClick}
                handleRegionSelect={this.handleRegionSelect}
                regions={regions}
                business_areas={business_areas}
                products={products}
                services={services}
                sectors={sectors}
                searchByName={this.handleSearch}
              />
            </section>
            {distributors.length === 0 ? (
              <div className="text-center">
                <h1>No results found</h1>
              </div>
            ) : (
              <div className="row no-gutters">
                <div className="col-md-4">
                  <section className="partners-block">
                    <PartnersList
                      partners={distributors}
                      onSelect={this.handlePartnerSelect}
                      activeId={activeId}
                    />
                  </section>
                </div>
                <div className="col">
                  <section className="partners-block">
                    <Back onClick={() => this.setState({ activeId: null })} />
                    <PartnersDetails
                      partner={activePartner}
                      partnerInfo={distributorsInfo[activeId]}
                      baseData={baseData}
                    />
                  </section>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  partnerDetails: state.PartnerReducer.partnerDetails,
  distributors: state.PartnerReducer.distributors,
  distributorsInfo: state.PartnerReducer.distributorsInfo,
  searchErr: state.PartnerReducer.searchErr,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(RFPActionCreators, dispatch),
  parActions: bindActionCreators(PartnerActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(ViewRFPs)
);
