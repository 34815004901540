import React from "react";

export const AuthRightPanel = () => (
  <div className="auth-right-panel">
    <div>
      {/* <img src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/images/partners/pl-partner-dashboard.png" /> */}
    </div>
    <h2>
        Customer discovery made easy.
    </h2>
    <p>
        Join Aesthetix Connect to search our open database of customer service requests listed by independent aesthetic professionals worldwide. Invite your team and request testimonials to boost your visibility to customers who are searching for the perfect distributor for their long-term needs.
    </p>
  </div>
);
