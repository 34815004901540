import React, { Component } from "react";
import { BytehogsPillContainer } from "../profile/BytehogsPillContainer";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { PartnerActionCreators } from "../../redux/actions/partner";
import { GeneralActionCreators } from "../../redux/actions/general";
import { connect } from "react-redux";

const serviceCosts = [
  { id: 1, name: "< 10,000" },
  { id: 2, name: "10 - 20,000" },
  { id: 3, name: "20 - 30,000" },
  { id: 4, name: "30 - 40,000" },
];

class OnBoardingService extends Component {
  state = { active: [], errors: {}, loading: false };

  async componentWillMount() {
    const {
      actions,
      genActions,
      baseData,
      partnerDetails = false,
    } = this.props;
    if (JSON.stringify(partnerDetails) === "{}") {
      try {
        await actions.getDetails();
        this.loadData();
      } catch (e) {
        console.log("e", e);
      }
    } else this.loadData();
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        console.log("e", e);
      }
    }
  }

  loadData = () => {
    const {
      partnerDetails: { filters, serviceCost },
      theKey,
    } = this.props;
    if (theKey === "regions") {
      const { regions } = filters;
      const theRegions = regions ? regions.map((x) => ({ value: x })) : [];
      this.setState({ active: theRegions });
    } else if (theKey === "cost") {
      const cost = [serviceCosts.find((x) => x.name === serviceCost && x).id];
      this.setState({ active: cost });
    } else this.setState({ active: filters[theKey] || [] });
  };

  setValue = (key, value) => this.setState({ [key]: value });

  selectAll = () => {
    const { baseData, theKey } = this.props;
    let active = [];
    baseData[theKey].forEach((x) => active.push(x.id));
    this.setState({ active });
  };

  handlePillClick = (key, id, multi = true) => {
    let data = this.state.active;
    if (data.includes(id)) data.splice(data.indexOf(id), 1);
    else multi ? data.push(id) : (data = [id]);
    this.setState({ active: data });
  };

  loadRegions = () => {
    const { baseData } = this.props;
    if (!baseData || !baseData.regions) return [];
    return baseData.regions.map((x) => ({ value: x.id, label: x.name }));
  };

  removeRegion = (value) => {
    let { active } = this.state;
    active = active.filter((x) => x.value !== value && x);
    if (active[0] === false) active = active.splice(0, 1);
    this.setState({ active });
  };

  handleSubmit = async () => {
    const { loading, active } = this.state;
    const { theKey } = this.props;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (active.length < 1) errors.active = "Required";
      if (Object.keys(errors).length > 0)
        this.setState({ errors, loading: false });
      else {
        const { actions } = this.props;
        try {
          //const serviceCost = serviceCosts.find(x => (x.id === cost[0]) && x).name;
          //await actions.updateDetails({serviceCost});
          let data = active;
          if (theKey === "regions")
            data = active.filter((e) => e).map((x) => x.value);
          await actions.updateFilters(theKey, data);
          const { updateDetailsErr, updateFiltersErr, onSuccess } = this.props;
          if (updateDetailsErr || updateFiltersErr)
            this.setState({
              errors: { join: updateDetailsErr || updateFiltersErr },
              loading: false,
            });
          else {
            this.setState({ loading: false });
            onSuccess();
          }
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  render() {
    const { errors, loading, active } = this.state;
    const { baseData, title, theKey, profile = false } = this.props;
    return (
      <div className="onboarding-service">
        {theKey === "regions" ? (
          <div className="service">
            <div className="pill-group">
              <label>{title}</label>
              <div className="select-all">
                <span>Type a country or region into the search box below</span>
              </div>
              <BytehogsPillContainer
                active={active.map((x) => x.value)}
                options={baseData.regions || []}
                onClick={this.removeRegion}
                selectedOnly
              />
              <Select
                value={active}
                isMulti
                hideSelectedOptions
                controlShouldRenderValue={false}
                backspaceRemovesValue={false}
                className="BHG-search-select"
                classNamePrefix="BHG-search-select"
                onChange={(x) => this.setValue("active", x || [])}
                options={this.loadRegions()}
              />
              {errors.active && (
                <div className="form-error">{errors.active}</div>
              )}
            </div>
            {!profile && (
              <button
                className="btn btn-primary btn-block"
                onClick={this.handleSubmit}
                disabled={loading}
              >
                {loading ? "Saving..." : "Next"}
              </button>
            )}
          </div>
        ) : (
          <div className="service">
            <div className="pill-group">
              <label>{title}</label>
              <div className="select-all">
                <span>Select all that apply</span>
                <div className="BHG-pill-container">
                  <button className="BHG-pill active" onClick={this.selectAll}>
                    Select all
                  </button>
                </div>
              </div>
              <BytehogsPillContainer
                active={active}
                options={baseData[theKey] || []}
                onClick={(x) => this.handlePillClick(theKey, x)}
              />
              {errors.active && (
                <div className="form-error">{errors.active}</div>
              )}
            </div>
            {!profile && (
              <button
                className="btn btn-primary btn-block"
                onClick={this.handleSubmit}
                disabled={loading}
              >
                {loading ? "Saving..." : "Next"}
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  partnerDetails: state.PartnerReducer.partnerDetails,
  updateDetailsErr: state.PartnerReducer.updateDetailsErr,
  updateFiltersErr: state.PartnerReducer.updateFiltersErr,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(OnBoardingService);
