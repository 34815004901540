import React, {useEffect, useState} from "react";
import { bindActionCreators } from "redux";
import { PartnerActionCreators } from "../../redux/actions/partner";
import { withToastManager } from "react-toast-notifications";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";

const ProfileDropdown = (props) => {
  const ref = React.useRef(null);
  const { partnerDetails, actions, setAdminOpen, user } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => setOpen(!open);

  useOnClickOutside(ref, () => open && toggle());

  if (JSON.stringify(partnerDetails) === "{}" && !loading) {
    setLoading(true);
    actions.getDetails();
    //setLoading(false);
  }

  useEffect(() => {
    actions.getUser();
  }, [])

  return (
    <div className={"profile-dropdown" + (open ? " open" : "")} ref={ref}>
      <div className="link" onClick={toggle}>
        <img src={partnerDetails.logo} alt="logo" />
      </div>
      <div className="menu">
        <nav>
          <ul>
            <li>
              <Link to="/profile" onClick={toggle}>
                Company profile
              </Link>
            </li>
            <li className="separator" />
            <li>
              <Link to="/settings" onClick={toggle}>
                Account settings
              </Link>
            </li>
            <li>
              <Link to="/team" onClick={toggle}>
                Manage team
              </Link>
            </li>
            <li className="separator" />
            {user && user.roleId === 3 && <>
              <li><span onClick={() => {setAdminOpen(true); toggle()}} className="link">Super Admin</span></li>
              <li className="separator"/>
            </>}
            <li>
              <Link to="/logout" onClick={toggle}>
                Sign out
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  partnerDetails: state.PartnerReducer.partnerDetails,
  user: state.PartnerReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown)
);
