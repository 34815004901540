import React, {Component} from "react";
import Modal from "react-modal";
import {bindActionCreators} from "redux";
import {PartnerActionCreators} from "../../redux/actions/partner";
import {connect} from "react-redux";
import {withToastManager} from "react-toast-notifications";

class UpdateUserModal extends Component {
        state = {name: "", notifications: 0, errors: {}, loading: false};

        componentWillReceiveProps(nextProps, nextContext) {
                if(nextProps.isOpen && !this.props.isOpen) this.setState({...nextProps.user, loading: false, errors: {}})
        }

        setValue = (key, value) => this.setState({[key]:value});

        handleSubmit = async e => {
                e.preventDefault();
                const {name,notifications,loading} = this.state
                if(!loading) {
                        let errors = {}
                        this.setState({errors, loading: true})
                        if (!name) errors.name = 'Required'
                        if (Object.keys(errors).length > 0) this.setState({errors, loading: false})
                        else {
                                const {actions} = this.props
                                try {
                                        await actions.updateUser({name, notifications});
                                        const {userErr, close, toastManager} = this.props;
                                        if(userErr) this.setState({errors: {join: userErr}, loading: false});
                                        else {
                                                toastManager.add("Successfully updated user", {
                                                        appearance: 'success',
                                                        autoDismiss: true,
                                                        pauseOnHover: false,
                                                })
                                                close();
                                                actions.getUser();
                                        }
                                } catch(e) {
                                        this.setState({errors: {join: e.message || "Sorry, something went wrong"}, loading: false})
                                }
                        }
                }
        }

        render() {
                const {isOpen, close} = this.props;
                const {name, notifications, errors, loading} = this.state;
                return <Modal
                    isOpen={isOpen}
                    onRequestClose={close}
                    className="modal update-user"
                    overlayClassName="modal-overlay"
                    contentLabel="Update User Modal">
                        <h1>Update User</h1>
                        <form onSubmit={this.handleSubmit}>
                                <div className="form-container">
                                        <div className="form-content">
                                                <label>Name</label>
                                                <div className="wl-input-group">
                                                        {errors.name && <div className="input-err-message">{errors.name}</div>}
                                                        <input type="text" className={"form-control" + (errors.name ? " error" : "")}
                                                               placeholder="Your Name" value={name}
                                                               onChange={e => this.setValue('name', e.target.value)}/>
                                                </div>
                                                <label>Notifications enabled</label>
                                                <div className="yes-no-buttons">
                                                        <button type="button" className={"btn " + (notifications === 1 ?"btn-success" : "btn-outline-success")} onClick={() => this.setValue('notifications', 1)}>Yes</button>
                                                        <button type="button" className={"btn " + (notifications === 0 ?"btn-danger" : "btn-outline-danger")} onClick={() => this.setValue('notifications', 0)}>No</button>
                                                </div>
                                                {errors.join && <div className="form-error">
                                                        {errors.join}
                                                </div>}
                                                <button type="submit" className="btn btn-primary"
                                                        disabled={loading}>{loading ? "Updating..." : "Update"}</button>
                                        </div>
                                </div>
                        </form>
                </Modal>
        }
}

const mapStateToProps = (state) => ({
        userErr: state.PartnerReducer.userErr,
        user: state.PartnerReducer.user,
});

const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(PartnerActionCreators, dispatch),
})

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(UpdateUserModal));

