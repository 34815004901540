import React, { Component } from "react";
import Modal from "react-modal";
import { bindActionCreators } from "redux";
import { PartnerActionCreators } from "../../redux/actions/partner";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { YearsSelector } from "../../components/partners/YearsSelector";

class UpdateDetailsModal extends Component {
  state = {
    name: "",
    website: "",
    bio: "",
    years: "0-1",
    errors: {},
    loading: false,
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.isOpen && !this.props.isOpen)
      this.setState({
        name: nextProps.partnerDetails.name,
        website: nextProps.partnerDetails.website,
        bio: nextProps.partnerDetails.bio,
        years: nextProps.partnerDetails.years,
        loading: false,
        errors: {},
        tab: 0,
      });
  }

  setValue = (key, value) => this.setState({ [key]: value });

  handleYearsChange = (e) => this.setValue("years", e.target.value);

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, website, bio, years, loading } = this.state;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (!name) errors.name = "Required";
      if (!website || website.length < 6) errors.website = "Required";
      else if (
        !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/i.test(
          website
        )
      )
        errors.website = "Invalid Website";
      if (!bio || bio.length < 6) errors.bio = "Required";
      if (Object.keys(errors).length > 0)
        this.setState({ errors, loading: false });
      else {
        const { actions } = this.props;
        try {
          await actions.updateDetails({ name, website, bio, years });
          const { userErr } = this.props;
          if (userErr)
            this.setState({ errors: { join: userErr }, loading: false });
          else this.success();
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  success = () => {
    const { actions, close, toastManager } = this.props;
    toastManager.add("Successfully updated details", {
      appearance: "success",
      autoDismiss: true,
      pauseOnHover: false,
    });
    close();
    actions.getDetails();
  };

  render() {
    const { isOpen, close } = this.props;
    const { website, name, bio, years, errors, loading } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        className="modal update-details"
        overlayClassName="modal-overlay"
        contentLabel="Update Details Modal"
      >
        <h1>Update Details</h1>
        <div className="tab-content">
          <form onSubmit={this.handleSubmit}>
            <div className="form-container">
              <div className="form-content">
                <label>Company Name</label>
                <div className="wl-input-group">
                  {errors.name && (
                    <div className="input-err-message">{errors.name}</div>
                  )}
                  <input
                    type="text"
                    className={"form-control" + (errors.name ? " error" : "")}
                    placeholder="Company Name"
                    value={name}
                    onChange={(e) => this.setValue("name", e.target.value)}
                  />
                </div>
                <label>Website</label>
                <div className="wl-input-group">
                  {errors.website && (
                    <div className="input-err-message">{errors.website}</div>
                  )}
                  <input
                    type="text"
                    className={
                      "form-control" + (errors.website ? " error" : "")
                    }
                    placeholder="Website"
                    value={website}
                    onChange={(e) => this.setValue("website", e.target.value)}
                  />
                </div>
                <label>Description</label>
                <div className="wl-input-group">
                  {errors.bio && (
                    <div className="input-err-message">{errors.bio}</div>
                  )}
                  <textarea
                    className={"form-control" + (errors.bio ? " error" : "")}
                    placeholder="Description."
                    value={bio}
                    onChange={(e) => this.setValue("bio", e.target.value)}
                    rows={4}
                  />
                </div>
                <label>How many years have you been a distributor?</label>
                <div className="wl-input-group">
                  {errors.years && (
                    <div className="input-err-message">{errors.years}</div>
                  )}
                  <YearsSelector
                    value={years}
                    onChange={this.handleYearsChange}
                  />
                </div>
                {errors.join && <div className="form-error">{errors.join}</div>}
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userErr: state.PartnerReducer.userErr,
  partnerDetails: state.PartnerReducer.partnerDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(UpdateDetailsModal)
);
