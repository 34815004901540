import React from "react";
import {getFullMonthName, getOrdinal} from "../../scripts/dates";

export const AwardDetails = props => {
    const {name, description, received} = props;
    const receivedDate = new Date(received);
    const dateString = getOrdinal(receivedDate.getDate()) + " " + getFullMonthName(receivedDate.getMonth()) + " " + receivedDate.getFullYear();

    return <div className="award-details">
        <div className="top">
            <span className="award-name">{name}</span>
            <span className="award-date">{dateString}</span>
        </div>
        <p>{description}</p>
    </div>
}
