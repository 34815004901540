import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GeneralActionCreators } from "../redux/actions/general";
import { PLTopBackground } from "../components/global/PLTopBackground";
import { withToastManager } from "react-toast-notifications";
import { SEO } from "../components/global/SEO";
import { RFPDetails } from "../components/RFPs/RFPDetails";
import { RFPActionCreators } from "../redux/actions/rfp";
import { Redirect } from "react-router-dom";

class RFPDetailPage extends Component {
  state = { loading: true, rfp: null };

  async componentWillMount() {
    const {
      genActions,
      baseData,
      toastManager,
      match: {
        params: { rfpId },
      },
    } = this.props;
    await this.getRFP(rfpId);
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        toastManager.add("Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      match: {
        params: { rfpId },
      },
    } = nextProps;
    if (rfpId !== this.props.match.params.rfpId) this.getRFP(rfpId);
  }

  getRFP = async (rfpId) => {
    const { actions } = this.props;
    this.setState({ loading: true });
    await actions.getRFPById(rfpId);
    const { rfpErr, rfpById, toastManager } = this.props;
    if (rfpErr) {
      toastManager.add("Something went wrong.", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });
    } else {
      if (!rfpById) {
        toastManager.add(`RFP ID:${rfpId} not found.`, {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
      this.setState({ rfp: rfpById, loading: false });
    }
  };

  render() {
    const { loading, rfp } = this.state;
    const { baseData } = this.props;
    if (loading) return <div>Loading...</div>;
    if (!rfp) return <Redirect to="/" />;
    return (
      <div className="view view-rfp">
        <SEO
          title={rfp.name + "(RFP) on Aesthetix Connect | Partners"}
          slug={"/rfp/" + rfp.id}
          creator="aesthetixconnect"
        />
        <PLTopBackground />
        <div className="main-content">
          <div className="container">
            <div className="row no-gutters">
              <div className="col">
                <section className="partners-block">
                  <RFPDetails RFP={rfp} baseData={baseData} />
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rfpById: state.RFPReducer.rfpById,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(RFPActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(RFPDetailPage)
);
