import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {PartnerActionCreators} from "../redux/actions/partner";
import {Link} from "react-router-dom";

const initialState = {companyName: "", name: "", email: "", note: "", errors: {}, loading: false};

class RequestTestimonialForm extends Component {
    state = initialState;

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.restart && !this.props.restart) this.handleRestart();
    }

    setValue = (key, value) => {
        this.setState({[key]:value});
        this.handleValidation(true);
    }

    handleRestart = () => this.setState(initialState);

    handleSubmit = async e => {
        e.preventDefault();
        const {companyName,name,email,note,loading} = this.state;
        const {onSuccess} = this.props;
        if(!loading) {
            let errors = {};
            this.setState({errors, loading: true});
            const validationErrors = this.handleValidation();
            if(validationErrors > 0) this.setState({loading: false});
            else {
                const {actions} = this.props
                try {
                    await actions.requestTestimonial({companyName,name,email,note});
                    const {testimonialErr} = this.props;
                    if(testimonialErr) this.setState({errors: {join: testimonialErr}, loading: false});
                    else {
                        this.setState({loading: false});
                        onSuccess();
                    }
                } catch(e) {
                    this.setState({errors: {join: e.message || "Sorry, something went wrong"}, loading: false})
                }
            }
        }
    }

    handleValidation = (onlyExistingErrors = false) => {
        const {companyName,name,email} = this.state;
        if(onlyExistingErrors && Object.keys(this.state.errors).length > 0 || !onlyExistingErrors) {
            let errors = {};
            this.setState({errors});
            if (!companyName || companyName.length < 2) errors.companyName = 'Required';
            if (!name || name.length < 2) errors.name = 'Required';
            if (!email) errors.email = 'Required';
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email)) errors.email = 'Invalid email';
            if (Object.keys(errors).length > 0) this.setState({errors});
            return Object.keys(errors).length;
        }
    }

    render() {
        const {companyName,name,email,note,errors, loading} = this.state;
        const {close = null} = this.props;
        return <form onSubmit={this.handleSubmit}>
            <div className="form-container">
                <div className="form-content">
                    <label>Company Name</label>
                    <div className="wl-input-group">
                        {errors.companyName && <div className="input-err-message">{errors.companyName}</div>}
                        <input type="text" className={"form-control" + (errors.companyName ? " error" : "")}
                               placeholder="Company Name" value={companyName}
                               onChange={e => this.setValue('companyName', e.target.value)}/>
                    </div>

                    <label>Email Address</label>
                    <div className="wl-input-group">
                        {errors.email && <div className="input-err-message">{errors.email}</div>}
                        <input type="text" className={"form-control" + (errors.email ? " error" : "")}
                               placeholder="Email Address" value={email}
                               onChange={e => this.setValue('email', e.target.value)}/>
                    </div>
                    <label>Product Name</label>
                    <div className="wl-input-group">
                        {errors.name && <div className="input-err-message">{errors.name}</div>}
                        <input type="text" className={"form-control" + (errors.name ? " error" : "")}
                               placeholder="Product Name" value={name}
                               onChange={e => this.setValue('name', e.target.value)}/>
                    </div>
                    <label>Add a Note</label>
                    <div className="wl-input-group">
                        <textarea className={"form-control" + (errors.note ? " error" : "")}
                                  placeholder="Add an optional note."
                                  value={note}
                                  onChange={e => this.setValue('note', e.target.value)} rows={3}/>
                    </div>
                    {errors.join && <div className="form-error">
                        {errors.join}
                    </div>}
                    <button type="submit" className="btn btn-primary btn-block"
                            disabled={loading}>{loading ? "Requesting..." : "Send Request"}</button>
                    <Link to="/profile" className="btn btn-outline-primary btn-block"
                            disabled={loading} onClick={close}>Do this later</Link>
                </div>
            </div>
        </form>
    }
}

const mapStateToProps = (state) => ({
    testimonialErr: state.PartnerReducer.testimonialErr
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(PartnerActionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestTestimonialForm);
