import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withToastManager} from "react-toast-notifications";
import {SEO} from "../components/global/SEO";
import {AccountDetailsCard} from "../components/profile/AccountDetailsCard";
import {PartnerActionCreators} from "../redux/actions/partner";

class Settings extends Component {
    async componentWillMount() {
        const {actions} = this.props;
        await actions.getUser();
        const {userErr, toastManager} = this.props;
        if(userErr) {
            toastManager.add("Something went wrong.", {
                appearance: 'error',
                autoDismiss: true,
                pauseOnHover: false,
            })
        }
    }

    render() {
        const {user} = this.props;
        return <div className="view team">
            <SEO title="Manage Account | Partners" slug="settings" creator="partnerlynx"/>
            <div className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <h3>Manage your account</h3>
                            <AccountDetailsCard account={user}/>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    user: state.PartnerReducer.user
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(PartnerActionCreators, dispatch)
})

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(Settings));
